import React, { useContext } from "react";
import {
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
  Button,
} from "@src/common/components";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import { useApproveShipmentDetailsMutation } from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useShipmentsCoreDataContext } from "../../context/ShipmentsCoreData";

interface ApproveShipmentDetailsModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ApproveShipmentDetailsModal: React.FC<ApproveShipmentDetailsModalProps> =
  ({ isModalOpen, onClose, onSuccess }) => {
    const { showToast } = useCourieStore();
    const { courierId, isEmployee, customClaims, courieUser } =
      useContext(AuthContext);
    const [approveShipmentDetailsMutation, { loading }] =
      useApproveShipmentDetailsMutation();
    const { shipmentSelectedForAction } = useShipmentSelectionContext();
    const { fetchShipments } = useShipmentsCoreDataContext();

    const handleApproveShipmentDetails = () => {
      if (!courierId || !shipmentSelectedForAction?.id) return;
      approveShipmentDetailsMutation({
        variables: {
          courierId: courierId,
          shipmentId: shipmentSelectedForAction?.id,
          updatedBy: isEmployee
            ? "EMPLOYEE"
            : courieUser?.id || customClaims?.courieUserId || undefined,
        },
      })
        .then((res) => {
          if (res.data?.approveShipmentDetails) {
            showToast({
              type: "success",
              message: "Shipment details approved successfully",
            });
            fetchShipments();
            onClose();
            onSuccess();
          }
        })
        .catch((e) => {
          showErrorToast(e, showToast);
        });
    };

    const renderPackageComparison = () => {
      const currentNumPackages = shipmentSelectedForAction?.numPackages;
      const proposedNumPackages =
        shipmentSelectedForAction?.proposedNumPackages;
      const currentWeight = shipmentSelectedForAction?.packageWeightInPounds;
      const proposedWeight =
        shipmentSelectedForAction?.proposedPackageWeightInPounds;

      return (
        <div className="space-y-6">
          {proposedNumPackages !== null &&
            proposedNumPackages !== currentNumPackages && (
              <div className="space-y-2">
                <h3 className="font-semibold text-gray-700">
                  Number of Packages
                </h3>
                <div className="flex items-center gap-4">
                  <div className="flex-1 p-3 bg-gray-50 rounded-md">
                    <div className="text-sm text-gray-500">Current</div>
                    <div className="font-medium">
                      {currentNumPackages || "Not set"}
                    </div>
                  </div>
                  <div className="flex-1 p-3 bg-yellow-50 rounded-md border border-yellow-200">
                    <div className="text-sm text-gray-500">Driver Reported</div>
                    <div className="font-medium">{proposedNumPackages}</div>
                  </div>
                </div>
              </div>
            )}

          {proposedWeight !== null && proposedWeight !== currentWeight && (
            <div className="space-y-2">
              <h3 className="font-semibold text-gray-700">
                Package Weight (lbs)
              </h3>
              <div className="flex items-center gap-4">
                <div className="flex-1 p-3 bg-gray-50 rounded-md">
                  <div className="text-sm text-gray-500">Current</div>
                  <div className="font-medium">
                    {currentWeight || "Not set"}
                  </div>
                </div>
                <div className="flex-1 p-3 bg-yellow-50 rounded-md border border-yellow-200">
                  <div className="text-sm text-gray-500">Driver Reported</div>
                  <div className="font-medium">{proposedWeight}</div>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4 border-t">
            <Button onClick={onClose} color="secondary" disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={handleApproveShipmentDetails}
              isProcessing={loading}
            >
              Approve Changes
            </Button>
          </div>
        </div>
      );
    };

    return (
      <BaseModal isOpen={isModalOpen} onClose={onClose}>
        <BaseModalHeader>
          Review Package Updates - {shipmentSelectedForAction?.order.displayId}
        </BaseModalHeader>
        <BaseModalBody>{renderPackageComparison()}</BaseModalBody>
      </BaseModal>
    );
  };

export default ApproveShipmentDetailsModal;
