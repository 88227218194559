import React, { useEffect, useMemo, useState } from "react";
import { useShipmentsCoreDataContext } from "@src/shipments/context/ShipmentsCoreData";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import { useCourieStore } from "@src/common/lib/store";
import DispatchCandidatesWidget from "@src/common/components/Widgets/DispatchCandidatesWidget/DispatchCandidatesWidget";
import { WidgetName } from "@src/common/components/Widgets/dashboardWidgetTypes";
import DriverMapWidget from "@src/common/components/Widgets/DriverMapWidget";
import OrderDetailsWidget, {
  OrderDetailsWidgetDataProps,
} from "@src/common/components/Widgets/OrderDetailsWidget";
import OrdersPriceWidget from "@src/common/components/Widgets/OrdersPriceWidget/OrdersPriceWidget";
import CustomerWidget from "@src/common/components/Widgets/CustomerWidget/CustomerWidget";
import {
  DispatchPreviewSaveInput,
  Driver,
  OrderPrice,
  useGetOrderPriceLazyQuery,
} from "@api/graphql/generated/generated-types";
import { makeOrderDetailsWidgetPropsFromShipment } from "@src/shipments/utils/orderDetailsWidgetMaker";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useModalsContext } from "@src/shipments/context/ShipmentsTableModalsContext";
import { OrdersPageModalNames } from "@src/shipments/utils/ordersPageModalNames";
import { useShipmentsTableContext } from "@src/shipments/context/ShipmentsTableContext";
import { LayersManager } from "baseui/layer";
import AssignDriverModal from "@src/common/components/Widgets/DispatchCandidatesWidget/components/AssignDriverModal";
import { useKeyboardControlContext } from "@src/shipments/context/ShipmentKeyboardControlContext";
import { useShipmentFiltersContext } from "@src/shipments/context/ShipmentFiltersContext";
import { useRouter } from "next/router";

const WidgetContainer = ({ selectedWidget, setCusorWait }) => {
  const { pathname } = useRouter();
  const { setModalOpen, setSelectedShipmentIdToUnassign, key } =
    useModalsContext();
  const { setSelectedShipmentRowToChangeRouting } = useShipmentsTableContext();
  const { selectedRow, setShipmentSelectedForAction } =
    useShipmentSelectionContext();
  const { refetchGetShipments, onDispatch } = useShipmentsCoreDataContext();
  const { showToast } = useCourieStore();
  const { resetKeyboardStates } = useKeyboardControlContext();
  const { minDate, maxDate } = useShipmentFiltersContext();

  const [
    getOrderPrice,
    {
      data: GetOrderPriceData,
      loading: GetOrderPriceLoading,
      refetch: refetchGetOrderPrice,
    },
  ] = useGetOrderPriceLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  // states
  const [orderDetails, setOrderDetails] = useState<
    OrderDetailsWidgetDataProps[]
  >([]);
  const [isAssignDriverModalOpen, setIsAssignDriverModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] =
    useState<Driver | undefined>(undefined);
  const [dispatchPreviewSortingType, setDispatchPreviewSortingType] =
    useState<string | undefined>(undefined);
  const [dispatchPreviews, setDispatchPreviews] =
    useState<DispatchPreviewSaveInput | DispatchPreviewSaveInput[] | undefined>(
      undefined
    );
  const [driverRank, setDriverRank] = useState<number | undefined>(undefined);
  const [dispatchPreviewSection, setDispatchPreviewSection] =
    useState<string | null>(null);

  const orderPrice = GetOrderPriceData?.orderPrice;
  const orderId = useMemo(
    () => selectedRow?.shipment?.order.id,
    [selectedRow?.shipment?.order.id]
  );
  const showDispatchPreviews = pathname !== "/office/sales";

  useEffect(() => {
    setCusorWait(GetOrderPriceLoading);
  }, [GetOrderPriceLoading]);

  useEffect(() => {
    refetchGetOrderPrice();
  }, [key]);

  useEffect(() => {
    if (selectedRow?.shipment) {
      const orderDetails = makeOrderDetailsWidgetPropsFromShipment(
        selectedRow.shipment,
        GetOrderPriceData?.orderPrice as OrderPrice | undefined
      );
      setOrderDetails(orderDetails);
    }
  }, [selectedRow?.shipment, GetOrderPriceData]);

  useEffect(() => {
    if (selectedRow?.shipment && orderId) {
      if (
        selectedWidget === WidgetName.ORDER_DETAILS ||
        selectedWidget === WidgetName.ORDERS_PRICE
      ) {
        getOrderPrice({
          variables: {
            orderId: orderId,
          },
        }).catch((e) => {
          showErrorToast(e, showToast);
        });
      }
    }
  }, [orderId, selectedWidget]);

  const renderWidget = () => {
    switch (selectedWidget) {
      case WidgetName.DISPATCH_CANDIDATES:
        return (
          <DispatchCandidatesWidget
            key={WidgetName.DISPATCH_CANDIDATES}
            selectedRow={selectedRow}
            onClickChangeRouting={() => {
              if (!selectedRow?.canChangeRouting) return;
              setSelectedShipmentRowToChangeRouting(selectedRow);
              setModalOpen(true, OrdersPageModalNames.CHANGE_ROUTING);
              setShipmentSelectedForAction(selectedRow.shipment);
            }}
            onClickUnassign={() => {
              if (!selectedRow) {
                return;
              }
              setSelectedShipmentIdToUnassign(selectedRow.id);
              setModalOpen(true, OrdersPageModalNames.UNASSIGN);
            }}
            onClickTransferDriver={() => {
              if (!selectedRow) {
                return;
              }
              setModalOpen(true, OrdersPageModalNames.CHANGE_DRIVER);
            }}
            isAssignDriverModalOpen={isAssignDriverModalOpen}
            setIsAssignDriverModalOpen={setIsAssignDriverModalOpen}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            setDispatchPreviewSortingType={setDispatchPreviewSortingType}
            setDispatchPreviews={setDispatchPreviews}
            setDriverRank={setDriverRank}
            setDispatchPreviewSection={setDispatchPreviewSection}
          />
        );
      case WidgetName.DRIVERS_MAP:
        return (
          <DriverMapWidget
            key="anotherWidget"
            shipment={selectedRow?.shipment}
            showDispatchPreviews={
              !selectedRow?.shipment?.driver || showDispatchPreviews
            }
            defaultBoundaryIncludeDrivers={!!selectedRow?.shipment?.driver}
            showBadge={false}
            showDriverDetail={true}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            isAssignDriverModalOpen={isAssignDriverModalOpen}
            setIsAssignDriverModalOpen={setIsAssignDriverModalOpen}
          />
        );
      case WidgetName.ORDER_DETAILS:
        return <OrderDetailsWidget orderDetails={orderDetails} key={key} />;
      case WidgetName.ORDERS_PRICE:
        return selectedRow?.shipment ? (
          <OrdersPriceWidget
            selectedShipments={[selectedRow.shipment]}
            ordersPrice={
              (orderPrice ? [orderPrice] : undefined) as
                | OrderPrice[]
                | undefined
            }
            loading={GetOrderPriceLoading}
            onModifyOrderPricing={() => refetchGetShipments()}
            key={key}
            refetchGetOrderPrice={() => {
              refetchGetOrderPrice();
            }}
            selectedRow={selectedRow}
            minDate={minDate}
            maxDate={maxDate}
          />
        ) : null;
      case WidgetName.CUSTOMER:
        return selectedRow?.shipment?.order ? (
          <CustomerWidget
            endCustomer={selectedRow.shipment.order.endCustomer}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      {renderWidget()}
      {isAssignDriverModalOpen && (
        <LayersManager zIndex={200}>
          <AssignDriverModal
            isOpen={isAssignDriverModalOpen}
            setIsOpen={setIsAssignDriverModalOpen}
            selectedRow={selectedRow}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            dispatchPreviewSortingType={dispatchPreviewSortingType}
            dispatchPreviews={dispatchPreviews}
            driverRank={driverRank}
            dispatchPreviewSection={dispatchPreviewSection}
            onDispatch={onDispatch}
            resetKeyboardStates={resetKeyboardStates}
          />
        </LayersManager>
      )}
    </>
  );
};

export default WidgetContainer;
