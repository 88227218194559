import { Driver } from "@api/graphql/generated/generated-types";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  Loading,
} from "@src/common/components";
import CourieButton from "@src/common/components/Button/Button";
import React, { useEffect, useState } from "react";
import DriverSelectorList from "./DriverSelectorList";
import { SIZE } from "baseui/modal";
import ReplaceAllocationView from "@src/common/components/ReplaceAllocationView/ReplaceAllocationView";
import AssignDriverModal from "@src/common/components/Widgets/DispatchCandidatesWidget/components/AssignDriverModal";
import { RouteViewMode } from "@src/common/components/ChangeDriverRouteView/ChangeDriverRouteView";
import { ShipmentRow } from "@src/shipments/types";

export enum ChangeDriverModalSlides {
  SELECT_DRIVER = "SELECT_DRIVER",
  CHANGE_ROUTE = "CHANGE_ROUTE",
  REPLACE_ALLOCATION = "REPLACE_ALLOCATION",
}

type Props = {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  selectedRow: ShipmentRow;
  loading: boolean;
  resetKeyboardStates?: () => void;
  onDispatch?: (lastChangedShipmentId?: string | null | undefined) => void;
  minDate: Date | undefined;
  maxDate: Date | undefined;
};

function ChangeDriverModal({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  selectedRow,
  loading,
  resetKeyboardStates,
  onDispatch: onDispatchFromParent,
  minDate,
  maxDate,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState<ChangeDriverModalSlides>(
    ChangeDriverModalSlides.SELECT_DRIVER
  );
  const [selectedDriver, setSelectedDriver] =
    useState<Driver | undefined>(undefined);

  const memoizedSelectedRow = React.useMemo(() => {
    return selectedRow;
  }, [selectedRow?.id]);

  const handleNextSlide = () => {
    selectedDriver && setCurrentSlide(ChangeDriverModalSlides.CHANGE_ROUTE);
  };

  useEffect(() => {
    if (isModalOpen) {
      setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
      setSelectedDriver(undefined);
    }
  }, [isModalOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedDriver(undefined);
    setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
  };

  const onDispatch = () => {
    setCurrentSlide(ChangeDriverModalSlides.REPLACE_ALLOCATION);
  };

  if (!isModalOpen) {
    return null;
  }
  return (
    <>
      <BaseModal
        isOpen={isModalOpen}
        size={SIZE.auto}
        onClose={() => {
          onModalClose();
        }}
      >
        <BaseModalHeader>
          {currentSlide === ChangeDriverModalSlides.REPLACE_ALLOCATION ? (
            "Replace Allocation"
          ) : (
            <>Shipment {memoizedSelectedRow?.orderDisplayId}: Change Driver </>
          )}
        </BaseModalHeader>
        <BaseModalBody>
          {currentSlide === ChangeDriverModalSlides.SELECT_DRIVER && (
            <div>
              <DriverSelectorList
                setSelectedDriver={setSelectedDriver}
                selectedDriver={selectedDriver}
                hideDriverId={selectedRow?.driverId}
              />
            </div>
          )}
          {currentSlide === ChangeDriverModalSlides.REPLACE_ALLOCATION && (
            <Loading loading={loading} style={{ minWidth: 350 }}>
              <ReplaceAllocationView
                driverPayAllocations={
                  selectedRow?.shipment?.driverPayAllocations
                }
                onConfirm={() => {
                  onConfirm();
                  setIsModalOpen(false);
                }}
                selectedRow={selectedRow}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Loading>
          )}
        </BaseModalBody>
        {currentSlide !== ChangeDriverModalSlides.REPLACE_ALLOCATION && (
          <BaseModalFooter>
            <div className="flex justify-between border-t pt-3">
              <CourieButton
                className="border mr-2"
                color={"secondary"}
                onClick={() => onModalClose()}
              >
                Cancel
              </CourieButton>
              <div className="flex gap-1">
                {currentSlide === ChangeDriverModalSlides.SELECT_DRIVER && (
                  <CourieButton
                    onClick={handleNextSlide}
                    disabled={!selectedDriver}
                  >
                    Next
                  </CourieButton>
                )}
              </div>
            </div>
          </BaseModalFooter>
        )}
      </BaseModal>
      <AssignDriverModal
        isOpen={currentSlide === ChangeDriverModalSlides.CHANGE_ROUTE}
        setIsOpen={(isOpen: boolean) => {
          setIsModalOpen(isOpen);
          if (!isOpen) {
            setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
          }
        }}
        selectedRow={selectedRow}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        submitButtonLabel={`Assign ${selectedRow?.shipment?.driver?.firstName}'s tasks`}
        onConfirm={onDispatch}
        routeViewMode={RouteViewMode.ChangeDriver}
        onDispatch={onDispatchFromParent}
        resetKeyboardStates={resetKeyboardStates}
      />
    </>
  );
}

export default ChangeDriverModal;
