import React from "react";
import {
  UserGroupIcon,
  Cog8ToothIcon,
  RocketLaunchIcon,
  PaperAirplaneIcon,
  UsersIcon,
  UserCircleIcon,
  IdentificationIcon,
  ReceiptPercentIcon,
  PresentationChartBarIcon,
  PuzzlePieceIcon,
  GlobeAmericasIcon,
  CalculatorIcon,
  RectangleStackIcon,
  CalendarIcon,
  CreditCardIcon,
  PresentationChartLineIcon,
  CubeIcon,
  BookOpenIcon,
  PlusIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import {
  PaperAirplaneIcon as PaperAirplaneSolidIcon,
  PresentationChartBarIcon as PresentationChartBarSolidIcon,
  UserGroupIcon as UserGroupSolidIcon,
  Cog8ToothIcon as Cog8ToothSolidIcon,
} from "@heroicons/react/24/solid";

import { NavItem, SUBMENU_ITEM } from "../types";
import { isLocalOrDev } from "@src/common/utils/environment";

export const NAV_ITEMS: NavItem[] = [
  {
    label: "Deliveries",
    href: "/deliveries/orders",
    icon: <PaperAirplaneIcon className="h-6 w-6" />,
    solidIcon: <PaperAirplaneSolidIcon className="h-6 w-6" />,
    active: true,
  },
  {
    label: "Office",
    href: "/office/customers",
    icon: <PresentationChartBarIcon className="h-6 w-6" />,
    solidIcon: <PresentationChartBarSolidIcon className="h-6 w-6" />,
  },
  {
    label: "Team",
    href: "/team/drivers",
    icon: <UserGroupIcon className="h-6 w-6" />,
    solidIcon: <UserGroupSolidIcon className="h-6 w-6" />,
  },
  {
    label: "Configuration",
    href: "/configuration/pricingrules",
    icon: <Cog8ToothIcon className="h-6 w-6" />,
    solidIcon: <Cog8ToothSolidIcon className="h-6 w-6" />,
  },
];

export const SUBMENU_ITEMS: SUBMENU_ITEM[] = [
  {
    page: "deliveries",
    items: [
      {
        label: "Orders",
        href: "/deliveries/orders",
        icon: <RectangleStackIcon className="h-6 w-6" />,
      },
      {
        label: "Fleet",
        href: "/deliveries/fleet",
        icon: <RocketLaunchIcon className="h-6 w-6" />,
      },
      {
        label: "Recurring",
        href: "/deliveries/recurring",
        icon: <CalendarIcon className="h-6 w-6" />,
      },
      {
        label: "Create Order",
        href: "/orders/create",
        icon: <PlusIcon className="h-6 w-6" />,
      },
    ],
  },
  {
    page: "office",
    items: [
      {
        label: "Customers",
        href: "/office/customers",
        icon: <UsersIcon className="h-6 w-6" />,
      },
      {
        label: "Sales",
        href: "/office/sales",
        icon: <PresentationChartLineIcon className="h-6 w-6" />,
      },
      {
        label: "Invoices",
        href: "/office/invoice",
        icon: <ReceiptPercentIcon className="h-6 w-6" />,
      },
      {
        label: "Payroll",
        href: "/office/payroll",
        icon: <BanknotesIcon className="h-6 w-6" />,
      },
      ...(isLocalOrDev()
        ? [
            {
              label: "Payments",
              href: "/office/payments",
              icon: <CreditCardIcon className="h-6 w-6" />,
            },
          ]
        : []),
    ],
  },
  {
    page: "team",
    items: [
      {
        label: "Drivers",
        href: "/team/drivers",
        icon: <IdentificationIcon className="h-6 w-6" />,
      },
      {
        label: "Users",
        href: "/team/users",
        icon: <UserCircleIcon className="h-6 w-6" />,
      },
    ],
  },
  {
    page: "configuration",
    items: [
      {
        label: "Pricing",
        href: "/configuration/pricingrules",
        icon: <CalculatorIcon className="h-6 w-6" />,
      },
      {
        label: "Services",
        href: "/configuration/servicetypes",
        icon: <PuzzlePieceIcon className="h-6 w-6" />,
      },
      {
        label: "Zones",
        href: "/configuration/zones",
        icon: <GlobeAmericasIcon className="h-6 w-6" />,
      },
      {
        label: "Address Book",
        href: "/configuration/addressbook",
        icon: <BookOpenIcon className="h-6 w-6" />,
      },
      {
        label: "Package Types",
        href: "/configuration/packagetypes",
        icon: <CubeIcon className="h-6 w-6" />,
      },
    ],
  },
];
