import React, { useEffect, useState } from "react";
import { useTableControl } from "../context/TableControlContext";
import EndCustomerSearchInput from "../../endcustomers/components/EndCustomerSearchInput";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";
import { Input } from "@src/common/components";
import { useDebounce } from "usehooks-ts";

type Props = {};

const INPUT_DEBOUNCE_TIME = 700;

function OrdersFilter({}: Props) {
  const { filters, setFilters } = useTableControl();
  const {
    selectedCustomer,
    setSelectedCustomer,
    trackingNumbersFilter,
    setTrackingNumbersFilter,
    displayIdsFilter,
    setDisplayIdsFilter,
  } = useShipmentFiltersContext();
  // Local state
  const [trackingNumbersFilterInputValue, settrackingNumbersFilterInputValue] =
    useState(trackingNumbersFilter);
  const debouncedTrackingNumber = useDebounce(
    trackingNumbersFilterInputValue,
    INPUT_DEBOUNCE_TIME
  );
  const [dispalyIdsFilterInputValue, setDisplayIdsFilterInputValue] =
    useState<number | undefined>(displayIdsFilter);
  const debouncedDisplayIds = useDebounce(
    dispalyIdsFilterInputValue,
    INPUT_DEBOUNCE_TIME
  );

  // Effect for updating the context filter with the debounced value
  useEffect(() => {
    if (debouncedTrackingNumber.length > 3) {
      setTrackingNumbersFilter(debouncedTrackingNumber);
    }
  }, [debouncedTrackingNumber, setTrackingNumbersFilter]);

  useEffect(() => {
    setDisplayIdsFilter(debouncedDisplayIds);
  }, [debouncedDisplayIds, setDisplayIdsFilter]);

  useEffect(() => {
    // Setting up initial filters based on selected customer
    setFilters((prevFilters) => [
      { id: 0, type: "endCustomerId", value: selectedCustomer?.id || "" },
      ...prevFilters.filter((f) => f.type !== "endCustomerId"),
    ]);
  }, [selectedCustomer, setFilters]);

  useEffect(() => {
    setFilters((prevFilters) => [
      ...prevFilters.filter((f) => f.type !== "trackingNumbersFilter"),
      { id: 1, type: "trackingNumbersFilter", value: trackingNumbersFilter },
    ]);
  }, [trackingNumbersFilter]);

  useEffect(() => {
    setFilters((prevFilters) => [
      ...prevFilters.filter((f) => f.type !== "displayIdsFilter"),
      { id: 2, type: "displayIdsFilter", value: displayIdsFilter },
    ]);
  }, [displayIdsFilter]);

  const returnInputType = (watchType) => {
    if (watchType && watchType.id === "endCustomerId") {
      return (
        <EndCustomerSearchInput
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          size="mini"
        />
      );
    }
    if (watchType && watchType.id === "trackingNumbersFilter") {
      return (
        <Input
          size="mini"
          placeholder="Tracking Number"
          value={trackingNumbersFilterInputValue}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length === 0) {
              setTrackingNumbersFilter("");
            }
            settrackingNumbersFilterInputValue(value);
          }}
          clearable={true}
        />
      );
    }
    if (watchType && watchType.id === "displayIdsFilter") {
      return (
        <Input
          size="mini"
          type="number"
          min={1}
          placeholder="Order Id"
          value={dispalyIdsFilterInputValue}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length === 0) {
              setDisplayIdsFilter(undefined);
            }
            setDisplayIdsFilterInputValue(
              value === "" ? undefined : Number(value)
            );
          }}
          clearable={true}
        />
      );
    }
    return null;
  };

  return (
    <div className="bg-white border min-w-max p-3 shadow-2xl rounded-md">
      <h3 className="mb-3 font-semibold">Filter By</h3>
      <div className="flex flex-col gap-3">
        {filters
          .sort((a, b) => a.id - b.id)
          .map((filter) => {
            let watchType:
              | {
                  id: string;
                  label: string;
                }
              | undefined = undefined;
            if (filter.type === "endCustomerId") {
              watchType = {
                id: "endCustomerId",
                label: "Customer",
              };
            }
            if (filter.type === "trackingNumbersFilter") {
              watchType = {
                id: "trackingNumbersFilter",
                label: "Tracking Number",
              };
            }
            if (filter.type === "displayIdsFilter") {
              watchType = {
                id: "displayIdsFilter",
                label: "ID",
              };
            }
            return (
              <div
                key={filter.id}
                className="flex items-center gap-4"
                style={{ width: 500 }}
              >
                <div className="text-xs w-1/5 truncate">{watchType?.label}</div>
                <div className=" w-4/5">{returnInputType(watchType)}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default OrdersFilter;
