import React, { useContext, useEffect, useState } from "react";
import { DriverAvatar } from "@src/common/components";
import { Select } from "baseui/select";
import {
  DriverEdge,
  useFetchDriverTasksByDateAndStatusLazyQuery,
  TaskStatus,
  Driver,
  useGetDriversLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { makeDriverOptions } from "../utils/makeDriverOptions";
import { isSameDay, isToday } from "@src/common/lib/DateUtils";
import moment from "moment";
import StatusDisplay from "@src/common/components/StatusDisplay";
import { DRIVER_API_LIMIT } from "@src/common/constants/apiConstants";

type Props = {
  driverId?: string;
  setDriverId: (driverId: string | undefined) => void;
  clearable?: boolean;
  driverNameCharLimit?: number;
  showAvatar?: boolean;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  bodyTop?: number;
};

export interface DriverOption {
  name: string;
  id: string;
  url: string | undefined;
  driver?: Driver | null;
  isActive?: boolean;
}

const sortDriversByName = (drivers: DriverOption[]) => {
  return drivers.sort((a, b) => a.name.localeCompare(b.name));
};

function deepCompare(prevProps: Props, nextProps: Props): boolean {
  const isSame = prevProps.driverId === nextProps.driverId;
  return isSame;
}

type GroupedDrivers = {
  "Active Drivers": DriverOption[];
  "Offline Drivers": DriverOption[];
};

const groupDriversByAvailability = (
  drivers: DriverOption[]
): GroupedDrivers => {
  const groupedDrivers: GroupedDrivers = {
    "Active Drivers": [],
    "Offline Drivers": [],
  };

  drivers.forEach((driver) => {
    if (driver.isActive) {
      groupedDrivers["Active Drivers"].push(driver);
    } else {
      groupedDrivers["Offline Drivers"].push(driver);
    }
  });

  return groupedDrivers;
};

const selectOverrides = ({ bodyTop }: { bodyTop: number }) => ({
  ControlContainer: {
    style: ({ $theme, $isFocused, $isPseudoFocused }) => ({
      backgroundColor:
        $isFocused || $isPseudoFocused
          ? $theme.colors.backgroundAlt
          : "transparent",
      borderColor: $theme.colors.border, // Replace with your button's border color
      borderWidth: "1px",
      borderRadius: $theme.borders.radius400, // Adjust for a rounded appearance
      height: "27px", // Adjust height to match the button
      boxShadow: "none",
      ":hover": {
        backgroundColor: $theme.colors.backgroundSecondary,
        borderColor: $theme.colors.borderHover, // Replace with your button's hover border color
      },
    }),
  },
  Placeholder: {
    style: ({ $theme }) => ({
      ...$theme.typography.font200, // Adjust to match your button's font
      color: $theme.colors.contentSecondary, // Replace with your button's font color
      lineHeight: "initial", // Adjust line height to vertically center the text
    }),
  },
  SingleValue: {
    style: ({ $theme }) => ({
      ...$theme.typography.font200, // Adjust to match your button's font
      color: $theme.colors.contentPrimary, // Replace with your button's font color
      display: "flex", // Ensure the layout is flex to align items
      alignItems: "center", // Center items vertically
      gap: $theme.sizing.scale300, // Ensure there is space between text and the image
    }),
  },
  DropdownListItem: {
    style: ({ $theme }) => ({
      ...$theme.typography.font200, // Adjust to match your button's font
      color: $theme.colors.contentPrimary, // Replace with your button's font color
    }),
  },
  ClearIcon: {
    style: {
      // Adjust the size properties as needed
      width: "24px", // Set the width you want
      height: "24px", // Set the height you want
    },
    props: {
      // Optionally, you can add additional SVG properties if necessary
      size: 24, // This size will be used if the icon is a custom component that accepts a `size` prop
    },
  },
  Dropdown: {
    style: {
      maxHeight: "70vh",
    },
  },
  Popover: {
    props: {
      overrides: {
        Body: {
          style: {
            top: `${bodyTop}px`,
          },
        },
      },
    },
  },

  // Custom component override if you're using one for the image
  CustomSingleValue: {
    // This key should match the actual component key you're using
    style: ({ $theme }) => ({
      display: "flex", // Ensure the layout is flex to align items
      alignItems: "center", // Center items vertically
    }),
  },
});

const DriverFilterSelectorComponent: React.FC<Props> = ({
  driverId,
  setDriverId,
  clearable = true,
  driverNameCharLimit = 11,
  showAvatar = true,
  minDate,
  maxDate,
  bodyTop = 40,
}) => {
  const { courierId } = useContext(AuthContext);
  const [drivers, setDrivers] = useState<GroupedDrivers>({
    "Active Drivers": [],
    "Offline Drivers": [],
  });
  const [
    FetchDriverTasksByDateAndStatus,
    {
      loading: FetchDriverTasksByDateAndStatusLoading,
      error: FetchDriverTasksByDateAndStatusError,
    },
  ] = useFetchDriverTasksByDateAndStatusLazyQuery();
  const [getDrivers, { loading: GetDriversLoading }] = useGetDriversLazyQuery();

  useEffect(() => {
    const sameDay = isSameDay(minDate, maxDate);
    const isTodaySelected = sameDay && minDate && isToday(minDate);
    if (isTodaySelected && courierId) {
      FetchDriverTasksByDateAndStatus({
        variables: {
          courierId,
          date: moment().format("YYYY-MM-DD"),
          statusFilter: [TaskStatus.Created, TaskStatus.Started],
          limit: DRIVER_API_LIMIT,
        },
      }).then((res) => {
        const fetchedDrivers = makeDriverOptions(
          (res.data?.drivers?.edges as DriverEdge[]) || []
        );
        const sortedDrivers = sortDriversByName(fetchedDrivers);
        const groupedDrivers = groupDriversByAvailability(sortedDrivers);
        setDrivers(groupedDrivers);
      });
    } else {
      const variables = {
        courierId: courierId!,
        limit: DRIVER_API_LIMIT,
      };
      getDrivers({
        variables,
      }).then((res) => {
        const fetchedDrivers = makeDriverOptions(
          (res?.data?.drivers?.edges as DriverEdge[]) || []
        );
        const sortedDrivers = sortDriversByName(fetchedDrivers);
        const groupedDrivers = groupDriversByAvailability(sortedDrivers);
        setDrivers(groupedDrivers);
      });
    }
  }, [minDate, maxDate]);

  const renderNumCurrentTasks = (option: DriverOption): React.ReactNode => {
    const taskCount = option.driver?.tasks?.length ?? 0;
    const sameDay = isSameDay(minDate, maxDate);
    const isTodaySelected = sameDay && minDate && isToday(minDate);
    if (!isTodaySelected) {
      return null;
    }
    return (
      <div className="flex items-center">
        <StatusDisplay value={taskCount} />
      </div>
    );
  };

  const getOptionLabel = ({ option }: any) => {
    return (
      <React.Fragment>
        <div className="flex gap-2 items-center">
          {showAvatar && (
            <div className="flex-1" style={{ maxWidth: 32 }}>
              <DriverAvatar
                onClick={() => {}}
                driverName={option.name}
                driverPhoto={option.url}
                isActive={true}
                className="cursor-pointer"
              />
            </div>
          )}
          <div className="flex-auto flex flex-col">
            <div className="truncate">{option.name}</div>
            {renderNumCurrentTasks(option)}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getValueLabel = ({ option }: any) => (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        {showAvatar && (
          <DriverAvatar
            size={16}
            onClick={() => {}}
            driverName={option.name}
            driverPhoto={option.url}
            isActive={true}
            className="cursor-pointer"
          />
        )}
        <div>
          {option.name.length > 11
            ? option.name.slice(0, driverNameCharLimit) + "..."
            : option.name}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Select
      size="mini"
      options={drivers}
      clearable={clearable}
      placeholder="Select driver"
      isLoading={GetDriversLoading || FetchDriverTasksByDateAndStatusLoading}
      value={
        driverId !== undefined
          ? [
              ...drivers["Active Drivers"]
                .concat(drivers["Offline Drivers"])
                .filter((driver) => driver.id === driverId),
            ]
          : []
      }
      onChange={(options) => {
        if (options.value.length === 0) {
          setDriverId(undefined);
        } else {
          setDriverId(options.value[0]?.id as string);
        }
      }}
      labelKey="name"
      valueKey="id"
      getOptionLabel={getOptionLabel}
      getValueLabel={getValueLabel}
      overrides={selectOverrides({ bodyTop })}
    />
  );
};

const DriverFilterSelector = React.memo(
  DriverFilterSelectorComponent,
  deepCompare
);
DriverFilterSelector.displayName = "DriverFilterSelector";

export { DriverFilterSelector };
