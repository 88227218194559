import { ShipmentType } from "@api/graphql/generated/generated-types";

export function formatTitleCaseWithUnderscores(word: string): string {
  if (word) {
    return (
      word.charAt(0).toUpperCase() +
      word.slice(1).toLowerCase().replace(/_/g, " ")
    );
  }
  return "";
}

export function formatPhoneNumber(phoneNumber: string): string {
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Return empty if no digits are present
  if (cleanedNumber.length === 0) {
    return "";
  }

  // Formatting logic
  if (cleanedNumber.length <= 3) {
    return "(" + cleanedNumber;
  } else if (cleanedNumber.length <= 6) {
    return (
      "(" + cleanedNumber.substring(0, 3) + ")-" + cleanedNumber.substring(3)
    );
  } else if (cleanedNumber.length <= 10) {
    return (
      "(" +
      cleanedNumber.substring(0, 3) +
      ")-" +
      cleanedNumber.substring(3, 6) +
      "-" +
      cleanedNumber.substring(6, 10)
    );
  } else {
    return (
      "(" +
      cleanedNumber.substring(0, 3) +
      ")-" +
      cleanedNumber.substring(3, 6) +
      "-" +
      cleanedNumber.substring(6, 10) +
      " Ext. " +
      cleanedNumber.substring(10, 18)
    );
  }
}

export function makeFullName(
  firstName: string | undefined,
  lastName: string | undefined
): string {
  if (!firstName && !lastName) {
    return "";
  }
  if (!firstName) {
    return lastName || "";
  }
  if (!lastName) {
    return firstName || "";
  }
  return `${firstName} ${lastName}`;
}

export function camelCaseToHumanized(input: string): string {
  // Split the string at each point where a lowercase letter is followed by an uppercase letter
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first letter of each word and join them back together with spaces
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getOptionLabelForShipmentType(option) {
  if (option === ShipmentType.Simple) {
    return "Simple (2 stops)";
  }
  if (option === ShipmentType.RoundTrip) {
    return "Round Trip (3 stops)";
  }
  if (option === ShipmentType.MultiPickUp) {
    return "Multi Pick Up";
  }
  if (option === ShipmentType.MultiDropOff) {
    return "Multi Drop Off";
  }
  if (option === ShipmentType.MultiDropOffRoundTrip) {
    return "Multi Drop Off With Return";
  }
  return option.label;
}
