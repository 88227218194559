import type { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";
import Link from "next/link";
import classNames from "classnames";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@src/common/components";

export interface NavItemProps
  extends PropsWithChildren,
    Omit<ComponentProps<"div">, "text"> {
  active?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  solidIcon?: ReactNode;
  label: ReactNode;
  href: string;
  badgeCount?: number;
  popoverContent?: ReactNode;
}

const NavItem: FC<NavItemProps> = ({
  href,
  icon,
  solidIcon,
  label,
  active = false,
  className,
  badgeCount = 0,
  popoverContent,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconToDisplay = active || isOpen ? solidIcon : icon;

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => isOpen && setIsOpen(false)}
      >
        <PopoverTrigger>
          <Link href={href}>
            <a
              className={classNames(
                "flex py-1 px-4 rounded-full",
                {
                  "hover:bg-slate-200": !active,
                  "text-primary-600": active,
                },
                className
              )}
              style={{ height: 40 }}
            >
              <span
                className={classNames("flex items-center justify-center pb-1", {
                  "text-primary-600": isOpen,
                })}
              >
                {iconToDisplay}
              </span>
              <span
                className={classNames("ml-1 relative", {
                  "text-primary-600 font-medium": active,
                  "text-primary-600": isOpen,
                  "flex flex-col justify-center": true,
                })}
              >
                {label}
                {badgeCount > 0 && (
                  <span className="absolute -top-1 -right-3 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
                    {badgeCount}
                  </span>
                )}
              </span>
            </a>
          </Link>
        </PopoverTrigger>
        <PopoverContent
          className="absolute z-50 bg-white border border-slate-200 rounded-lg shadow-lg -mt-1.5"
          style={{
            zIndex: 1000,
            width: 220,
            minWidth: 220,
            maxWidth: 220,
            maxHeight: "calc(100vh - 80px)",
            overflowY: "auto",
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
          }}
          onMouseLeave={() => setIsOpen(false)}
        >
          {popoverContent}
        </PopoverContent>
      </div>
    </Popover>
  );
};

NavItem.displayName = "Nav.Item";
export default NavItem;
