import React, { useContext, useEffect, useState } from "react";
import { RadioWithLabel } from "@src/common/components";
import { ShipmentsPrimaryFilterType } from "@src/shipments/types";
import { FilterNameWithBadge } from "./FilterNameWithBadge";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";
import { useGetShipmentCountsForFiltersLazyQuery } from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import moment from "moment";
import {
  currentEpochSeconds,
  minutesAfterCurrentEpochSeconds,
} from "@src/common/lib/DateUtils";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import classNames from "classnames";

type PrimaryFiltersProps = {
  showFilters?: ShipmentsPrimaryFilterType[];
};

export default function PrimaryFilters({ showFilters }: PrimaryFiltersProps) {
  const { courierId } = useContext(AuthContext);
  const {
    shipmentsApiResponse,
    shipmentsOverviewCounts,
    setShipmentsOverviewCounts,
  } = useShipmentsCoreDataContext();
  const {
    selectedPrimaryFilter,
    setSelectedPrimaryFilter,
    minDate,
    maxDate,
    selectedDriverIdFilter,
  } = useShipmentFiltersContext();

  const [
    getShipmentsOverview,
    {
      data: shipmentsOverviewResponse,
      loading: shipmentsOverviewLoading,
      error: shipmentsOverviewApiError,
      refetch: refetchGetShipmentsOverview,
    },
  ] = useGetShipmentCountsForFiltersLazyQuery();

  const minDateString = moment(minDate).format("YYYY-MM-DD");
  const maxDateString = moment(maxDate).format("YYYY-MM-DD");

  useEffect(() => {
    getShipmentsOverview({
      variables: {
        courierId: courierId!,
        minExpectedCompletionDate: minDateString,
        maxShipmentDate: maxDateString,
        now: currentEpochSeconds(),
        in15Minutes: minutesAfterCurrentEpochSeconds(15),
        in30Minutes: minutesAfterCurrentEpochSeconds(30),
        in60Minutes: minutesAfterCurrentEpochSeconds(60),
      },
    });
  }, [shipmentsApiResponse]);

  useEffect(() => {
    refetchGetShipmentsOverview({
      courierId: courierId!,
      minExpectedCompletionDate: minDateString,
      maxShipmentDate: maxDateString,
      now: currentEpochSeconds(),
    });
  }, [selectedPrimaryFilter, minDate, maxDate, selectedDriverIdFilter]);

  useEffect(() => {
    if (shipmentsOverviewResponse) {
      setShipmentsOverviewCounts({
        numLate:
          shipmentsOverviewResponse?.shipmentsOverview.numLate || undefined,
        numLateIn15Minutes:
          shipmentsOverviewResponse?.shipmentsOverview.numLateIn15Minutes ||
          undefined,
        numUnassigned:
          shipmentsOverviewResponse?.shipmentsOverview.numUnassigned ||
          undefined,

        numAssigned:
          shipmentsOverviewResponse?.shipmentsOverview.numAssigned || undefined,
        numLateIn30Minutes:
          shipmentsOverviewResponse?.shipmentsOverview.numLateIn30Minutes ||
          undefined,
        numLateIn60Minutes:
          shipmentsOverviewResponse?.shipmentsOverview.numLateIn60Minutes ||
          undefined,
        numLikelyLate:
          shipmentsOverviewResponse?.shipmentsOverview.numLikelyLate ||
          undefined,
        numActive:
          shipmentsOverviewResponse?.shipmentsOverview.numActive || undefined,
        numDelivered:
          shipmentsOverviewResponse?.shipmentsOverview.numDelivered ||
          undefined,
        numClosed:
          shipmentsOverviewResponse?.shipmentsOverview.numClosed || undefined,
      });
    }
  }, [shipmentsOverviewResponse]);

  const shouldShowFilter = (
    filterType: ShipmentsPrimaryFilterType
  ): boolean => {
    if (!showFilters) return true; // If no filters specified, show all
    return showFilters.includes(filterType);
  };

  return (
    <div className="level-1 flex items-center ml-3 my-auto">
      <fieldset className="flex gap-1" id="shipments-primary-filter">
        {shouldShowFilter(ShipmentsPrimaryFilterType.ACTIVE) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1 pb-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.ACTIVE === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ACTIVE)
            }
          >
            <RadioWithLabel
              id="Active"
              name="Active"
              value="Active"
              htmlFor="Active"
              checked={
                ShipmentsPrimaryFilterType.ACTIVE === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numActive}
                color="gray"
              >
                Active
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.UNASSIGNED) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.UNASSIGNED === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.UNASSIGNED)
            }
          >
            <RadioWithLabel
              id="Unassigned"
              name="Unassigned"
              value="Unassigned"
              htmlFor="Unassigned"
              checked={
                ShipmentsPrimaryFilterType.UNASSIGNED === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numUnassigned}
                color="warning"
              >
                Unassigned
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.ASSIGNED) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.ASSIGNED === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ASSIGNED)
            }
          >
            <RadioWithLabel
              id="Assigned"
              name="Assigned"
              value="Assigned"
              htmlFor="Assigned"
              checked={
                ShipmentsPrimaryFilterType.ASSIGNED === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numAssigned}
                color="gray"
              >
                Assigned
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.LIKELY_LATE) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.LIKELY_LATE ===
                selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.LIKELY_LATE)
            }
          >
            <RadioWithLabel
              id="AT_RISK"
              name="AT_RISK"
              value="AT_RISK"
              htmlFor="AT_RISK"
              checked={
                ShipmentsPrimaryFilterType.LIKELY_LATE === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numLikelyLate}
                color="warning"
              >
                At Risk
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.LATE) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.LATE === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.LATE)
            }
          >
            <RadioWithLabel
              id="Late"
              name="Late"
              value="Late"
              htmlFor="Late"
              checked={
                ShipmentsPrimaryFilterType.LATE === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numLate}
                color="failure"
              >
                Late
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.DELIVERED) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.DELIVERED === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.DELIVERED)
            }
          >
            <RadioWithLabel
              id="Delivered"
              name="Delivered"
              value="Delivered"
              htmlFor="Delivered"
              checked={
                ShipmentsPrimaryFilterType.DELIVERED === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numDelivered}
                color="success"
              >
                Delivered
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.CLOSED) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.CLOSED === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.CLOSED)
            }
          >
            <RadioWithLabel
              id="Closed"
              name="Closed"
              value="Closed"
              htmlFor="Closed"
              checked={
                ShipmentsPrimaryFilterType.CLOSED === selectedPrimaryFilter
              }
              onChange={() => null}
            >
              <FilterNameWithBadge
                badgeContent={shipmentsOverviewCounts?.numClosed}
                color="gray"
              >
                Closed
              </FilterNameWithBadge>
            </RadioWithLabel>
          </div>
        )}

        {shouldShowFilter(ShipmentsPrimaryFilterType.ALL) && (
          <div
            className={classNames({
              "hover:bg-slate-100 rounded-md px-1 pt-0.5": true,
              "bg-primary-100":
                ShipmentsPrimaryFilterType.ALL === selectedPrimaryFilter,
            })}
            onClick={() =>
              setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ALL)
            }
          >
            <RadioWithLabel
              id="All"
              name="All"
              value="All"
              htmlFor="All"
              checked={ShipmentsPrimaryFilterType.ALL === selectedPrimaryFilter}
              onChange={() => null}
            >
              All
            </RadioWithLabel>
          </div>
        )}
      </fieldset>
    </div>
  );
}
