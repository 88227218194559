import {
  DispatchPreviewSaveInput,
  DispatchSource,
  DispatchStopsOnDayInput,
  Driver,
  OrderingAlgorithmType,
  ShipmentStopType,
  Stop,
  useDispatchDriverForShipmentMultiDayMutation,
  useDispatchDriverForShipmentMutation,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { DriverAvatar } from "@src/common/components/Avatar";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
} from "@src/common/components/BaseModal";
import CourieButton from "@src/common/components/Button/Button";
import ChangeDriverRouteView, {
  RouteViewMode,
} from "@src/common/components/ChangeDriverRouteView/ChangeDriverRouteView";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { useKeyboardControlContext } from "@src/shipments/context/ShipmentKeyboardControlContext";
import { useShipmentsCoreDataContext } from "@src/shipments/context/ShipmentsCoreData";
import { ShipmentRow } from "@src/shipments/types";
import { SIZE } from "baseui/modal";
import React, { useContext, useEffect, useState } from "react";
import {
  getStopIds,
  getUniqueStopDates,
} from "../utils/assignDriverModalUtils";
import StopDateListTimeline from "@src/common/components/ChangeDriverRouteView/components/StopDateListTimeline";
import { useLocalStorage } from "usehooks-ts";
import { findIfShipmentIsMultiDay } from "@src/common/lib/shipmentStateFinders";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedRow: ShipmentRow;
  selectedDriver: Driver | undefined;
  setSelectedDriver: (driver: Driver | undefined) => void;
  submitButtonLabel?: string;
  onConfirm?: () => void;
  dispatchPreviewSortingType?: string | undefined;
  dispatchPreviews?:
    | DispatchPreviewSaveInput
    | DispatchPreviewSaveInput[]
    | undefined;
  driverRank?: number | undefined;
  dispatchPreviewSection?: string | null;
  routeViewMode?: RouteViewMode;
  onDispatch?: (lastChangedShipmentId?: string | null | undefined) => void;
  resetKeyboardStates?: () => void;
};

function AssignDriverModal({
  isOpen,
  setIsOpen,
  selectedRow,
  selectedDriver,
  setSelectedDriver,
  submitButtonLabel = "Assign",
  onConfirm,
  dispatchPreviewSortingType,
  dispatchPreviews,
  driverRank,
  dispatchPreviewSection,
  routeViewMode = RouteViewMode.Dispatch,
  onDispatch,
  resetKeyboardStates,
}: Props) {
  // context
  const { courieUser, isEmployee } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  // mutation
  const [
    dispatchDriverForShipmentMutation,
    { loading: DispatchDriverForShipmentMutationLoading },
  ] = useDispatchDriverForShipmentMutation();
  const [
    dispatchDriverForShipmentMultiDayMutation,
    { loading: DispatchDriverForShipmentMultiDayMutationLoading },
  ] = useDispatchDriverForShipmentMultiDayMutation();
  // states
  const [routeDetails, setRouteDetails] =
    useState<Stop[] | undefined>(undefined);
  const [isValidOrder, setIsValidOrder] = useState(true);
  // const [selectedOrderingAlgorithmType, setSelectedOrderingAlgorithmType] =
  //   useState(OrderingAlgorithmType.Manual);
  const [autoRoutedStopIds, setAutoRoutedStopIds] =
    useState<undefined | string[]>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [listStopDates, setListStopDates] = useState<string[]>([]);
  const [selectedStopDate, setSelectedStopDate] = useState("");
  const [daysGrouped, setDaysGrouped] = useState<{
    [date: string]: DispatchStopsOnDayInput;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderingAlgorithmType, setSelectedOrderingAlgorithmType] =
    useState<OrderingAlgorithmType | undefined>(
      OrderingAlgorithmType.LeastDeviation
    );

  const isLastStopDate =
    listStopDates === undefined ||
    listStopDates.length === 0 ||
    listStopDates.indexOf(selectedStopDate) === listStopDates.length - 1;

  const memoizedRouteDetails = React.useMemo(() => {
    return routeDetails;
  }, [routeDetails]);

  const memoizedSelectedRow = React.useMemo(() => {
    return selectedRow;
  }, [selectedRow?.id]);

  useEffect(() => {
    if (!selectedRow) return;
    const isMultidayShipment =
      selectedRow.shipment &&
      findIfShipmentIsMultiDay(
        selectedRow.shipment.stops,
        selectedRow.shipment.shipmentDate
      );
    if (
      isMultidayShipment &&
      memoizedRouteDetails &&
      memoizedRouteDetails.length > 0
    ) {
      const newDaysGrouped = groupStopsByDate(
        memoizedRouteDetails as Stop[],
        selectedStopDate,
        autoRoutedStopIds
      );
      newDaysGrouped &&
        setDaysGrouped((prevDays) => ({
          ...prevDays,
          [selectedStopDate]: newDaysGrouped[0],
        }));
    }
  }, [memoizedRouteDetails, selectedStopDate]);

  // effects
  useEffect(() => {
    if (!selectedDriver) {
      return;
    }
    const orderStops = memoizedSelectedRow?.shipment?.stops || [];
    const uncompletedStops = orderStops.filter((stop) => !stop.completedAt);
    const routeDetails = uncompletedStops.sort((a, b) => {
      if (
        a.type === ShipmentStopType.PickUp &&
        b.type !== ShipmentStopType.PickUp
      )
        return -1;
      if (
        a.type !== ShipmentStopType.PickUp &&
        b.type === ShipmentStopType.PickUp
      )
        return 1;
      return 0;
    });
    const listStopDates: undefined | string[] =
      getUniqueStopDates(routeDetails);
    let routeDetailsForSelectedDate: Stop[] = [];
    if (listStopDates && listStopDates.length > 1) {
      setListStopDates(listStopDates);
      if (!selectedStopDate) {
        setSelectedStopDate(listStopDates[0]);
      }

      routeDetailsForSelectedDate = routeDetails.filter((routeDetail) => {
        const stopDate = routeDetail.stopDate;
        return stopDate === selectedStopDate;
      });
    } else {
      const shipmentDate = memoizedSelectedRow?.shipment?.shipmentDate;
      if (listStopDates && shipmentDate !== listStopDates[0]) {
        setSelectedStopDate(listStopDates[0]);
      } else {
        setSelectedStopDate(shipmentDate);
      }
      routeDetailsForSelectedDate = routeDetails;
    }
    setRouteDetails(routeDetailsForSelectedDate);
  }, [selectedDriver, memoizedSelectedRow, selectedStopDate]);

  const handleAssignDriver = () => {
    if (!selectedRow || !selectedDriver || !selectedOrderingAlgorithmType) {
      return;
    }
    const isMultidayShipment =
      selectedRow.shipment &&
      findIfShipmentIsMultiDay(
        selectedRow.shipment.stops,
        selectedRow.shipment.shipmentDate
      );
    if (listStopDates.length > 1 || isMultidayShipment) {
      const days = Object.values(daysGrouped);
      dispatchDriverForShipmentMultiDayMutation({
        variables: {
          shipmentId: selectedRow.id,
          driverId: selectedDriver.id,
          orderingAlgorithmType: selectedOrderingAlgorithmType,
          dispatchedBy: isEmployee ? "EMPLOYEE" : courieUser?.id || "",
          dispatchSource: DispatchSource.Dispatcher,
          days,
          dispatchPreviewSortingType,
          dispatchPreviews: dispatchPreviews,
          driverRank,
          dispatchPreviewSection,
        },
      })
        .then(() => {
          handleSuccessDispatchResponse();
        })
        .catch((error) => {
          showErrorToast(error, showToast);
        });
      return;
    }
    const stopIds = getStopIds(routeDetails);
    let DispatchStopsOnDayInput: DispatchStopsOnDayInput = {
      autoRoutedStopIds: autoRoutedStopIds ? autoRoutedStopIds : [],
      stopIds: stopIds,
      stopDate: selectedRow.shipment?.shipmentDate || selectedStopDate,
      orderingAlgorithmType: selectedOrderingAlgorithmType,
    };
    dispatchDriverForShipmentMultiDayMutation({
      variables: {
        shipmentId: selectedRow.id,
        driverId: selectedDriver.id,
        orderingAlgorithmType: selectedOrderingAlgorithmType,
        dispatchedBy: isEmployee ? "EMPLOYEE" : courieUser?.id || "",
        dispatchSource: DispatchSource.Dispatcher,
        days: [DispatchStopsOnDayInput],
        dispatchPreviewSortingType,
        dispatchPreviews: dispatchPreviews,
        driverRank,
        dispatchPreviewSection,
      },
    })
      .then(() => {
        handleSuccessDispatchResponse();
      })
      .catch((error) => {
        showErrorToast(error, showToast);
      });
  };

  const handleClose = () => {
    setSelectedDriver(undefined);
    setRouteDetails(undefined);
    setSelectedStopDate("");
  };

  const handleSuccessDispatchResponse = () => {
    handleClose();
    onDispatch && onDispatch();
    resetKeyboardStates && resetKeyboardStates();
    showToast({
      message: "The order is dispatched successfully",
      type: "success",
    });
    if (onConfirm) {
      onConfirm();
    } else {
      setIsOpen(false);
    }
  };

  function groupStopsByDate(
    routeDetails: Stop[] | undefined,
    selectedStopDate: string,
    autoRoutedStopIds: string[] | undefined
  ): DispatchStopsOnDayInput[] | undefined {
    if (!routeDetails || !selectedOrderingAlgorithmType) return undefined;

    const groupedByDate: { [key: string]: Stop[] } = {};

    routeDetails.forEach((stop) => {
      const date = stop.stopDate || selectedStopDate;
      if (date) {
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date].push(stop);
      }
    });

    return Object.entries(groupedByDate).map(([date, stops]) => ({
      autoRoutedStopIds,
      orderingAlgorithmType: selectedOrderingAlgorithmType,
      stopDate: date,
      stopIds: stops.map((stop) => stop.id),
    }));
  }

  const handleAssignOrNext = () => {
    if (isLastStopDate) {
      handleAssignDriver();
    } else {
      const nextStopDateIndex = listStopDates.indexOf(selectedStopDate) + 1;
      setSelectedStopDate(listStopDates[nextStopDateIndex]);
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
        handleClose();
        setIsOpen(false);
      }}
      size={SIZE.auto}
      autoFocus={false}
    >
      <BaseModalHeader>
        {" "}
        <div className="flex justify-between">
          <div className="flex gap-2">
            <DriverAvatar
              driverPhoto={selectedDriver?.photoUrl}
              driverName={selectedDriver?.firstName}
              size={25}
            />
            Assign Task to {selectedDriver?.firstName}
          </div>
          <div>
            <StopDateListTimeline
              listStopDates={listStopDates}
              selectedStopDate={selectedStopDate}
              shipmentDate={memoizedSelectedRow?.shipment?.shipmentDate || ""}
            />
          </div>
          <span className="mr-3"></span>
        </div>{" "}
      </BaseModalHeader>
      <BaseModalBody
        style={{ minWidth: 1200, height: "70vh", overflow: "scroll" }}
      >
        {routeDetails && (
          <ChangeDriverRouteView
            loading={false}
            routeDetails={routeDetails}
            setRouteDetails={setRouteDetails}
            isValidOrder={isValidOrder}
            setIsValidOrder={setIsValidOrder}
            driver={selectedDriver}
            selectedShipment={memoizedSelectedRow?.shipment}
            setSelectedOrderingAlgorithmType={setSelectedOrderingAlgorithmType}
            selectedOrderingAlgorithmType={selectedOrderingAlgorithmType}
            routeViewMode={routeViewMode}
            setAutoRoutedStopIds={setAutoRoutedStopIds}
            setErrorMessage={setErrorMessage}
            selectedStopDate={selectedStopDate}
            setLoading={setIsLoading}
          />
        )}
      </BaseModalBody>
      <BaseModalFooter className="flex justify-between">
        {listStopDates && listStopDates.length > 1 ? (
          <CourieButton
            color={"secondary"}
            onClick={() => {
              const prevStopDateIndex =
                listStopDates.indexOf(selectedStopDate) - 1;
              if (prevStopDateIndex >= 0) {
                setSelectedStopDate(listStopDates[prevStopDateIndex]);
              } else {
              }
            }}
          >
            Back
          </CourieButton>
        ) : (
          <CourieButton
            color={"secondary"}
            onClick={() => {
              handleClose();
              setIsOpen(false);
            }}
          >
            Cancel
          </CourieButton>
        )}
        <div className="flex">
          {errorMessage && (
            <span className="text-red-600 my-auto pr-5">{errorMessage}</span>
          )}
          <CourieButton
            isProcessing={
              DispatchDriverForShipmentMutationLoading ||
              DispatchDriverForShipmentMultiDayMutationLoading ||
              isLoading
            }
            onClick={() => handleAssignOrNext()}
            disabled={
              !isValidOrder ||
              isLoading ||
              DispatchDriverForShipmentMutationLoading ||
              DispatchDriverForShipmentMultiDayMutationLoading
            }
          >
            {isLastStopDate
              ? `${submitButtonLabel} to ${selectedDriver?.firstName}`
              : "Next"}
          </CourieButton>
        </div>
      </BaseModalFooter>
    </BaseModal>
  );
}

export default AssignDriverModal;
