import React, { useEffect, useMemo, useState } from "react";
import {
  OrderPrice,
  Shipment,
  ShipmentStatus,
  useOrderPayBreakDownLazyQuery,
} from "@api/graphql/generated/generated-types";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { OrdersPriceForm } from "./components/OrdersPriceForm";
import { useRouter } from "next/router";
import OverridePricingDetailForm from "./components/OverridePricingDetailForm";
import { Loading } from "../../Loading";
import PricingBreakDownTable from "./components/PricingBreakDownTable/PricingBreakDownTable";
import DriverCompensation from "./components/DriverCompensation/DriverCompensation";
import ReplaceAllocationView from "../../ReplaceAllocationView/ReplaceAllocationView";
import { ShipmentRow } from "@src/shipments/types";

type Props = {
  selectedShipments: Shipment[] | null | undefined;
  ordersPrice: OrderPrice[] | undefined;
  onModifyOrderPricing?: () => void;
  key?: number;
  loading?: boolean;
  refetchGetOrderPrice?: () => void;
  selectedRow?: ShipmentRow;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
};

function OrdersPriceWidget({
  selectedShipments,
  ordersPrice,
  onModifyOrderPricing,
  key,
  loading = false,
  refetchGetOrderPrice,
  selectedRow,
  minDate,
  maxDate,
}: Props) {
  //contexts
  const { pathname } = useRouter();
  //graphql
  const [
    fetchOrderPayBreakDown,
    {
      data: fetchOrderPayBreakDownData,
      loading: fetchOrderPayBreakDownLoading,
      refetch: refetchOrderPayBreakDown,
    },
  ] = useOrderPayBreakDownLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.orderPayBreakDown) {
        setDriverPayTotal(data.orderPayBreakDown.total);
      }
    },
  });
  // states
  const [driverPayTotal, setDriverPayTotal] = useState<number | undefined>(0);
  const selectedShipment = selectedShipments && selectedShipments[0];
  const selectedSingleShipmentMemo = useMemo(
    () => selectedShipments && selectedShipments[0],
    [
      selectedShipment?.order.displayId,
      selectedShipment?.order,
      selectedShipment?.waitTimeInMinutes,
    ]
  );

  // effects
  useEffect(() => {
    refetchGetOrderPrice && refetchGetOrderPrice();
  }, [key]);

  const renderOrderNumber = (index) => {
    if (
      selectedShipments &&
      selectedShipments?.length === 1 &&
      selectedShipments[0] !== undefined
    ) {
      return `${selectedShipments[0].order.displayId}`;
    } else if (
      selectedShipments &&
      selectedShipments?.length > 1 &&
      selectedShipments[index] !== undefined
    ) {
      return `${selectedShipments[index].order.displayId}`;
    }
    return "";
  };

  const getShipmentFromMultipleShipments = (index) => {
    if (
      selectedShipments &&
      selectedShipments?.length === 1 &&
      selectedShipments[0] !== undefined
    ) {
      return selectedShipments[0];
    } else if (
      selectedShipments &&
      selectedShipments?.length > 1 &&
      selectedShipments[index] !== undefined
    ) {
      return selectedShipments[index];
    }
    return undefined;
  };

  const waitTimeInMinutes =
    selectedSingleShipmentMemo?.waitTimeInMinutes !== undefined;
  const driverPayAllocations = selectedSingleShipmentMemo?.driverPayAllocations;

  const status = selectedSingleShipmentMemo?.status;
  if (ordersPrice && ordersPrice.length > 0) {
    return (
      <Loading
        loading={loading}
        className="p-3 h-full border bg-white dark:bg-gray-800 rounded-2xl overflow-auto"
      >
        <span className="font-semibold mb-2">Price</span>
        {ordersPrice.map((orderPrice, index) => (
          <div className="rounded-lg mb-2 border shadow-md" key={index}>
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500/75">
                    <span>Order {renderOrderNumber(index)}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-slate-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-1 pb-2 pt-1 text-sm text-gray-500">
                    {orderPrice.isFinalized ? (
                      <PricingBreakDownTable
                        pricingBreakDown={orderPrice.pricingBreakDown}
                        total={orderPrice.total}
                      />
                    ) : (
                      <OrdersPriceForm
                        onModifyOrderPricing={() => {
                          refetchOrderPayBreakDown();
                          onModifyOrderPricing && onModifyOrderPricing();
                          refetchGetOrderPrice && refetchGetOrderPrice();
                        }}
                        selectedShipment={getShipmentFromMultipleShipments(
                          index
                        )}
                        orderPrice={orderPrice}
                      />
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
        {(pathname === "/deliveries/orders" ||
          pathname === "/office/sales") && (
          <DriverCompensation
            selectedSingleShipmentMemo={selectedSingleShipmentMemo}
            fetchOrderPayBreakDown={fetchOrderPayBreakDown}
            fetchOrderPayBreakDownData={fetchOrderPayBreakDownData}
            fetchOrderPayBreakDownLoading={fetchOrderPayBreakDownLoading}
            setDriverPayTotal={setDriverPayTotal}
            driverPayTotal={driverPayTotal}
          />
        )}
        {(pathname === "/deliveries/orders" || pathname === "/office/sales") &&
          waitTimeInMinutes &&
          ordersPrice &&
          !ordersPrice[0].isFinalized && (
            <>
              <span className="font-semibold">Custom Pricing</span>
              <div className="rounded-lg mb-2 border shadow-md">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500/75">
                        <span>
                          Order {selectedSingleShipmentMemo.order.displayId}
                        </span>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-slate-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-1 pb-2 pt-1 text-sm text-gray-500">
                        <OverridePricingDetailForm
                          shipment={selectedSingleShipmentMemo}
                          onOveridePrice={() => {
                            refetchGetOrderPrice && refetchGetOrderPrice();
                          }}
                          orderPrice={ordersPrice[0]}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </>
          )}
        {(pathname === "/deliveries/orders" || pathname === "/office/sales") &&
          status !== ShipmentStatus.Created && (
            <>
              <span className="font-semibold">Driver Allocations</span>
              <div className="rounded-lg mb-2 border shadow-md">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500/75">
                        <span>
                          Order {selectedSingleShipmentMemo?.order.displayId}
                        </span>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-slate-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-1 pb-2 pt-1 text-sm text-gray-500">
                        <ReplaceAllocationView
                          driverPayAllocations={driverPayAllocations}
                          selectedRow={selectedRow}
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </>
          )}
      </Loading>
    );
  } else {
    return (
      <Loading
        className="p-3 h-full border bg-white dark:bg-gray-800 rounded-2xl"
        loading={loading}
      ></Loading>
    );
  }
}

export default OrdersPriceWidget;
