import React, { useContext } from "react";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import { useUncloseShipmentsMutation } from "@api/graphql/generated/generated-types";
import WarningConfirmationModal from "@src/common/components/Modal/WarningConfirmationModal";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";

interface BulkUncloseShipmentsModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  setIsModalOpen: (open: boolean) => void;
}

const BulkUncloseShipmentsModal: React.FC<BulkUncloseShipmentsModalProps> = ({
  isModalOpen,
  onClose,
  onSuccess,
  setIsModalOpen,
}) => {
  const { showToast } = useCourieStore();
  const { courierId } = useContext(AuthContext);
  const { checkedShipments } = useShipmentSelectionContext();
  const [uncloseShipmentsMutation, { data, loading, error }] =
    useUncloseShipmentsMutation();
  const handleConfirm = async () => {
    if (!courierId) {
      return;
    }
    try {
      uncloseShipmentsMutation({
        variables: {
          courierId: courierId,
          shipmentIds: checkedShipments,
        },
      })
        .then(() => {
          showToast({
            message: "Shipments unclosed successfully",
            type: "success",
          });
          onSuccess();
        })
        .catch((error) => {
          showErrorToast(error, showToast);
        });
    } catch (error) {
      console.error("Error unclosing shipments:", error);
    }
  };

  return (
    <WarningConfirmationModal
      cancelButtonText="Cancel"
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      loading={loading}
      onConfirm={handleConfirm}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      title={`Unclose ${checkedShipments.length} Orders`}
      textBody={`Are you sure you want to unclose ${checkedShipments.length} orders?`}
      primaryButtonText={"Continue"}
    />
  );
};

export default BulkUncloseShipmentsModal;
