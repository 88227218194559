export enum OrdersPageModalNames {
  OVERRIDE_POD = "Override_POD",
  UNASSIGN = "Unassign",
  POD = "POD",
  CHANGE_ROUTING = "Change_Routing",
  FORCE_COMPLETE = "Force_Complete",
  DELETE = "Delete",
  RESET = "Reset",
  FAIL = "Fail",
  EDIT = "Edit",
  CHANGE_DRIVER = "Change_Driver",
  CREATE_ROUTING_JOB = "Create_Routing_Job",
  CREATE_ROUTING_JOB_WARNING = "Create_Routing_Job_Warning",
  ORDER_ATTACHMENTS = "Order_Attachments",
  BULK_UNASSIGN_DRIVERS = "Bulk_Unassign_Drivers",
  BULK_CLOSE_SHIPMENTS = "Bulk_Close_Shipments",
  BULK_UNCLOSE_SHIPMENTS = "Bulk_Unclose_Shipments",
  APPROVE_SHIPMENT_DETAILS = "APPROVE_SHIPMENT_DETAILS",
}
