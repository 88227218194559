/**
 * Utility functions for environment-specific checks
 */

/**
 * Checks if the current environment is local or development
 */
export const isLocalOrDev = (): boolean => {
  if (typeof window === "undefined") {
    // Server-side rendering
    return process.env.NODE_ENV === "development";
  }

  // Client-side - check if API URL is not production
  const apiUrl = process.env.NEXT_PUBLIC_GRAPHQL_SCHEMA_URL || "";
  return !apiUrl.includes("p2hv4nkmaq-uw"); // prod URL identifier
};
