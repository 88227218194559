import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AddressBookEntry,
  EndCustomer,
  Maybe,
  useSearchFromAddressBookLazyQuery,
} from "@api/graphql/generated/generated-types";
import {
  Controller,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";
import { Combobox, Label } from "@src/common/components";
import { useDebounce } from "usehooks-ts";
import { StopValueProps } from "../hooks/useAutoFill";
import {
  camelCaseToHumanized,
  formatPhoneNumber,
} from "@src/common/lib/textUtils";
import { LoadingSpinner } from "baseui/button/styled-components";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { UserIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface SuggestionInputProps {
  control: any;
  type: "contactName" | "companyName" | "phone";
  selectedCustomer: EndCustomer | undefined;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  shipmentStopsFormName: string;
  isStopFullUpdate?: boolean;
  usePositive?: boolean;
}

function SuggestionInput({
  control,
  type,
  selectedCustomer,
  setValue,
  clearErrors,
  shipmentStopsFormName,
  isStopFullUpdate = false,
  usePositive = true,
}: SuggestionInputProps) {
  const { courierId } = useContext(AuthContext);
  const inputRef = useRef<HTMLElement>(null);
  const [query, setQuery] = useState("");
  const [options, setOptions] = React.useState<AddressBookEntry[]>([]);
  const debouncedQueryValue = useDebounce<string>(query, 500);

  const [SearchFromAddressBook, { loading }] =
    useSearchFromAddressBookLazyQuery({
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (debouncedQueryValue.length > 0 && courierId) {
      const variables = {
        courierId: courierId,
        endCustomerIdBias: selectedCustomer?.id || undefined,
        contactNameSearch:
          type === "contactName" ? debouncedQueryValue : undefined,
        companyNameSearch:
          type === "companyName" ? debouncedQueryValue : undefined,
        phoneSearch: type === "phone" ? debouncedQueryValue : undefined,
      };
      SearchFromAddressBook({ variables }).then((res) => {
        const entries: AddressBookEntry[] =
          (res.data?.searchFromAddressBook as AddressBookEntry[]) || [];
        setOptions(entries);
      });
    }
  }, [
    debouncedQueryValue,
    courierId,
    selectedCustomer,
    type,
    SearchFromAddressBook,
  ]);

  const handleChange = (
    nextValue: string,
    addressBookEntry?: AddressBookEntry | null
  ) => {
    if (type === "phone") {
      nextValue = formatPhoneNumber(nextValue);
    }
    setQuery(nextValue);
    if (addressBookEntry) {
      const {
        streetAddress = "",
        googlePlaceId = "",
        city = "",
        country = "",
        state = "",
        zipCode = "",
      } = addressBookEntry.location;
      const suite = addressBookEntry.suite;
      const contactName = addressBookEntry.contactName;
      const contactPhoneNumber: string = addressBookEntry.phone
        ? formatPhoneNumber(addressBookEntry.phone)
        : "";
      const companyName: Maybe<string> | undefined =
        addressBookEntry.companyName;
      const instruction: Maybe<string> | undefined =
        addressBookEntry.instruction;
      const locationId = addressBookEntry.location.id;
      const lngLat = addressBookEntry.location.lngLat;
      let fullAddress = `${streetAddress}, ${city}, ${state}, ${country} ${zipCode}`;
      let valuesToSetInForm: any = {
        streetAddress: fullAddress,
        googlePlaceId,
        suite,
        contactName,
        phone: contactPhoneNumber,
        companyName,
        city,
        country,
        state,
        instruction,
        locationId: locationId ? locationId : undefined,
        lngLat,
      };
      if (isStopFullUpdate) {
        valuesToSetInForm = {
          streetAddress: fullAddress,
          companyName,
          contactName,
          googlePlaceId,
          phone: contactPhoneNumber,
          suite,
          instruction,
          locationId: locationId ? locationId : undefined,
          lngLat,
        };
      }
      setStopValues(valuesToSetInForm);
      clearErrors(`${shipmentStopsFormName}.${type}`);
      clearErrors(`${shipmentStopsFormName}.streetAddress`);
    } else {
      setValue(`${shipmentStopsFormName}.${type}`, nextValue);
    }
  };

  const mapOptionToString = (option: AddressBookEntry): string => {
    switch (type) {
      case "contactName":
        return option.contactName || "";
      case "companyName":
        return option.companyName || "";
      case "phone":
        return option.phone ? formatPhoneNumber(option.phone) : "";
      default:
        return "";
    }
  };

  const setStopValues = (values: StopValueProps) => {
    Object.keys(values).forEach((key) => {
      setValue(
        `${shipmentStopsFormName}.${key}`,
        values[key as keyof StopValueProps]
      );
    });
  };

  const displayValue = (option: AddressBookEntry) => {
    return mapOptionToString(option);
  };

  const SuggestionInputListItem: React.FC<{
    isSelected: boolean;
    option: unknown;
  }> = ({ isSelected, option }) => {
    const addressBookEntry = option as AddressBookEntry; // Type assertion

    return (
      <div
        className={classNames({
          "flex justify-between w-full hover:cursor-pointer": true,
          "opacity-50": addressBookEntry.endCustomerId !== selectedCustomer?.id,
        })}
      >
        <div className="w-4/5 flex flex-col">
          <div className="text-xs font-bold truncate">
            {displayValue(addressBookEntry)}
          </div>
          <div className="text-xs">
            {addressBookEntry.location.streetAddress}
            {addressBookEntry.suite && `, ${addressBookEntry.suite}`}
          </div>
        </div>
        <div className="w-1/5">
          {addressBookEntry.endCustomerId === selectedCustomer?.id ? (
            <UserIcon className="w-5 h-5 text-green-500" />
          ) : (
            <GlobeAltIcon className="w-5 h-5 text-blue-500" />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Label className="text-xs font-medium text-gray-700 flex justify-between truncate">
        <div>
          {camelCaseToHumanized(type)}
          <span className="text-slate-400 text-xs"> (Optional)</span>
        </div>
        <span>
          <LoadingSpinner $style={{ display: loading ? "block" : "none" }} />
        </span>
      </Label>
      <Controller
        name={`${shipmentStopsFormName}.${type}`}
        control={control}
        rules={{ required: false }}
        render={({ fieldState: { error }, field: { ref, value } }) => {
          ref(inputRef.current);
          if (value === undefined) {
            setQuery("");
          }
          return (
            <Combobox
              size="mini"
              inputRef={inputRef}
              error={!!error}
              onChange={(b, a) => handleChange(b, a as any)}
              mapOptionToString={(a) => mapOptionToString(a as any)}
              value={value}
              name={`${type}-options`}
              options={options}
              mapOptionToNode={SuggestionInputListItem}
              overrides={{
                Input: {
                  props: {
                    placeholder: selectedCustomer
                      ? `Search for a ${camelCaseToHumanized(type)}`
                      : "",
                    error: !!error,
                    autoComplete: "off",
                    type: type === "phone" ? "tel" : "text",
                    inputMode: type === "phone" ? "tel" : undefined,
                  },
                },
                ListItem: {
                  style: {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    height: "auto",
                  },
                },
              }}
            />
          );
        }}
      />
    </>
  );
}

export default SuggestionInput;
