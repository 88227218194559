import React, { useEffect, useRef, useState } from "react";
import MapGL, { Marker, Viewport } from "@urbica/react-map-gl";
import { BADGE_ENHANCER_SIZES, FixedMarker, KIND } from "baseui/map-marker";
import { useDriverRouteDataProvider } from "../contexts/DriverRouteDataProviderContext";
import {
  getBoundsFromLocationsNoLngLat,
  isValidLngLat,
} from "@src/common/lib/mapBoundCalculator";
import { initialViewport } from "@src/common/constants/mapConstants";
import {
  Driver,
  Maybe,
  OrderingAlgorithmType,
  ShipmentStatus,
  Stop,
  Task,
} from "@api/graphql/generated/generated-types";
import { DriverMarkerMap } from "./DriverMarkerMap";
import { useLocalStorage } from "usehooks-ts";
import { useDriverRouteViewSelectionProvider } from "../contexts/DriverRouteViewSelectionProvider";
import { extractLngLat } from "../utils/extractLngLat";
import { ErrorBoundary } from "react-error-boundary";
import MapErrorFallback from "../../MapErrorFallback/MapErrorFallback";
import { mapGLMapStyles } from "@src/common/constants/mapStyles";
import IconContainer from "@src/common/components/IconContainer";
import { HomeIcon as HomeIconSolid } from "@heroicons/react/24/solid";

type BadgeEnhancerContentProps = {
  item: Task | Stop;
  size: number;
};

interface Props {
  routeDetails: (Task | Stop)[] | undefined;
  viewportHeight: string;
  driver?: Maybe<Driver> | undefined;
}

const MapOnDriverRouteView: React.FC<Props> = ({
  routeDetails,
  viewportHeight,
  driver,
}) => {
  const { DriverWithLocationData, getDriverCurrentLocation } =
    useDriverRouteDataProvider();
  const {
    hoverTaskLabel,
    hoveredTask,
    selectedOrderingAlgorithmType,
    startLocation,
    endLocation,
  } = useDriverRouteViewSelectionProvider();
  const mapRef = useRef<MapGL>(null);
  const [isMapDragged, setIsMapDragged] = useState(false);
  const [viewport, setViewport] = useLocalStorage<Viewport>(
    "MapOnDriverRouteViewport",
    initialViewport
  );
  const driverHomeLocation = driver?.homeLocation;

  useEffect(() => {
    if (!driver) {
      return;
    }
    getDriverCurrentLocation({
      variables: {
        driverId: driver.id,
      },
    });
  }, [driver]);

  useEffect(() => {
    const mapFitBounds = () => {
      const map = mapRef.current?.getMap();
      if (!map || isMapDragged || !routeDetails?.length) return;
      let bounds: any = null;
      if (routeDetails && routeDetails.length > 0) {
        let allCoordinates: [number, number][] = [];
        const tasksFiltered =
          (routeDetails?.filter(
            (task) => task.__typename === "Task"
          ) as Task[]) || [];
        const stopsFiltered =
          (routeDetails?.filter(
            (task) => task.__typename === "Stop"
          ) as Stop[]) || [];

        tasksFiltered.forEach((task) => {
          if (isValidLngLat(task.stop.lngLat)) {
            allCoordinates.push([
              task.stop.lngLat.lng as number,
              task.stop.lngLat.lat as number,
            ]);
          }
        });

        stopsFiltered.forEach((stop) => {
          if (isValidLngLat(stop.lngLat)) {
            allCoordinates.push([
              stop.lngLat.lng as number,
              stop.lngLat.lat as number,
            ]);
          }
        });
        if (DriverWithLocationData) {
          const lastReportedLocation =
            DriverWithLocationData.driverById.lastReportedLocation;
          if (lastReportedLocation) {
            allCoordinates.push([
              lastReportedLocation.lngLat.lng as number,
              lastReportedLocation.lngLat.lat as number,
            ]);
          }
        }
        // Include driver's home location if available
        if (driverHomeLocation && driverHomeLocation.lngLat) {
          allCoordinates.push([
            driverHomeLocation.lngLat.lng as number,
            driverHomeLocation.lngLat.lat as number,
          ]);
        }
        // if selectedOrderingAlgorithmType is reoptimized, include start and end locations
        if (
          selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
        ) {
          startLocation &&
            allCoordinates.push([
              startLocation.lngLat.lng as number,
              startLocation.lngLat.lat as number,
            ]);
          endLocation &&
            allCoordinates.push([
              endLocation.lngLat.lng as number,
              endLocation.lngLat.lat as number,
            ]);
        }
        bounds = getBoundsFromLocationsNoLngLat(allCoordinates);
      }

      map.fitBounds(bounds, {
        padding: {
          top: 40,
          bottom: 100,
          left: 40,
          right: 40,
        },
        maxZoom: 13,
        pitch: 30,
      });
      map.once("moveend", () =>
        setViewport({
          longitude: map.getCenter().lng,
          latitude: map.getCenter().lat,
          zoom: map.getZoom(),
        })
      );
    };
    mapFitBounds();
  }, [
    routeDetails,
    DriverWithLocationData,
    driverHomeLocation,
    isMapDragged,
    setViewport,
    startLocation,
    endLocation,
    selectedOrderingAlgorithmType,
  ]);

  const BadgeEnhancerContent: React.FC<BadgeEnhancerContentProps> = ({
    item,
    size,
  }) => {
    if (
      item.__typename === "Stop" &&
      item.shipment.status === ShipmentStatus.Created
    ) {
      return <span style={{ fontSize: size }}>New</span>;
    }
    return null;
  };

  const returnStartLocation = () => {
    if (
      startLocation &&
      startLocation.lngLat.lng &&
      startLocation.lngLat.lat &&
      selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
    ) {
      return (
        <Marker
          longitude={startLocation.lngLat.lng}
          latitude={startLocation.lngLat.lat}
        >
          <FixedMarker label="Start" />
        </Marker>
      );
    }
  };

  const returnEndLocation = () => {
    if (
      endLocation &&
      endLocation.lngLat.lng &&
      endLocation.lngLat.lat &&
      selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
    ) {
      return (
        <Marker
          longitude={endLocation.lngLat.lng}
          latitude={endLocation.lngLat.lat}
        >
          <FixedMarker label="End" />
        </Marker>
      );
    }
  };

  return (
    <ErrorBoundary FallbackComponent={MapErrorFallback}>
      <MapGL
        ref={mapRef}
        style={{ width: "100%", height: viewportHeight }}
        mapStyle={mapGLMapStyles.light.url}
        accessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        onViewportChange={setViewport}
        onDrag={() => setIsMapDragged(true)}
        {...viewport}
      >
        {hoveredTask && (
          <Marker
            key={hoveredTask.id}
            longitude={extractLngLat(hoveredTask).lng || 0}
            latitude={extractLngLat(hoveredTask).lat || 0}
          >
            <FixedMarker
              label={hoverTaskLabel?.toString()}
              kind={KIND.accent}
            />
          </Marker>
        )}
        <DriverMarkerMap DriverWithLocationData={DriverWithLocationData} />
        {routeDetails?.map((item, index) => {
          const isStopAndNew =
            item.__typename === "Stop" &&
            item.shipment?.status === ShipmentStatus.Created;

          return (
            <Marker
              key={item.id}
              longitude={extractLngLat(item).lng || 0}
              latitude={extractLngLat(item).lat || 0}
            >
              <FixedMarker
                label={index + 1}
                badgeEnhancerContent={(props) => (
                  <BadgeEnhancerContent {...props} item={item} />
                )}
                badgeEnhancerSize={
                  isStopAndNew ? BADGE_ENHANCER_SIZES.mediumText : undefined
                }
              />
            </Marker>
          );
        })}
        {driverHomeLocation &&
          driverHomeLocation.lngLat?.lng != null &&
          driverHomeLocation.lngLat?.lat != null && (
            <Marker
              longitude={driverHomeLocation.lngLat.lng}
              latitude={driverHomeLocation.lngLat.lat}
            >
              <IconContainer size={36}>
                <HomeIconSolid className="w-6 h-6 text-primary-500" />
              </IconContainer>
            </Marker>
          )}
        {returnStartLocation()}
        {returnEndLocation()}
      </MapGL>
    </ErrorBoundary>
  );
};

export default MapOnDriverRouteView;
