import { Driver } from "@api/graphql/generated/generated-types";
import { Button, DriverAvatar } from "@src/common/components";
import {
  ArrowsUpDownIcon,
  EnvelopeIcon,
  PhoneIcon,
  UserMinusIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { ShipmentRow } from "@src/shipments/types";

function AssignedDriverView({
  driver,
  onClickUnassign,
  onClickChangeRouting,
  canUnassignDriver,
  canChangeRouting,
  onClickTransferDriver,
  canTransferDriver,
  selectedRow,
}: {
  driver: Driver;
  onClickUnassign?: () => void;
  onClickChangeRouting?: () => void;
  onClickTransferDriver?: () => void;
  canUnassignDriver: boolean;
  canChangeRouting: boolean;
  canTransferDriver: boolean;
  selectedRow: ShipmentRow | undefined;
}) {
  const router = useRouter();
  const routingJobId = selectedRow?.shipment?.routingJobId;
  return (
    <div className="flex flex-col items-center pt-4 justify-between">
      <div className="flex flex-col items-center">
        <DriverAvatar
          driverPhoto={driver.photoUrl || undefined}
          driverName={`${driver.firstName} ${driver.lastName}`}
          size={70}
          isActive={driver.isActive}
        />
        <p className="text-lg font-bold">{`${driver.firstName} ${driver.lastName}`}</p>
        <div className="mt-4 flex flex-col gap-3 ">
          <p className="text-sm text-gray-600">
            <PhoneIcon className="h-4 w-4 inline-block mr-4" />
            {driver.phone}
          </p>
          <p className="text-sm text-gray-600">
            <EnvelopeIcon className="h-4 w-4 inline-block mr-4" />

            {driver.email}
          </p>
        </div>
      </div>
      <div className="mt-10 w-full flex gap-2 flex-wrap justify-center">
        {/* Placeholder for action buttons */}
        <Button
          size={"xs"}
          onClick={onClickChangeRouting}
          disabled={!canChangeRouting}
        >
          <ArrowsUpDownIcon className="h-4 w-4 inline-block mr-2" />
          Change Routing
        </Button>
        <Button
          size={"xs"}
          outline={true}
          onClick={onClickUnassign}
          disabled={!canUnassignDriver}
        >
          <UserMinusIcon className="h-4 w-4 inline-block mr-2" />
          Unassign
        </Button>
        <Button
          size={"xs"}
          outline={true}
          onClick={onClickTransferDriver}
          disabled={!canTransferDriver}
        >
          <ArrowsRightLeftIcon className="h-4 w-4 inline-block mr-2" />
          Change Driver
        </Button>
        {routingJobId && (
          <Button
            size={"xs"}
            outline={true}
            onClick={() => {
              router.push(`/routingjob/${routingJobId}`);
            }}
          >
            <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1 inline-block" />
            View Route Optimization
          </Button>
        )}
      </div>
    </div>
  );
}

export default AssignedDriverView;
